<template>
  <div>
    <div v-if="round" class="row">
      <b-card class="col-12" title="Ganhadores">
        <div v-if="round.winners" class="d-flex">
          <div v-for="winner in round.winners" class="winner col">
            <h4 class="text-success">{{winner.prize.name}}</h4>
            <h4>{{winner.user.name}}</h4>
            <h4>{{winner.prize_collected | toCurrency}}</h4>
            <h4>Bola: {{winner.winner_number}}</h4>
            <h4>Sequencia: {{winner.winner_number_order}}º</h4>
            <h4 v-on:click="checkCard(winner.selled_card_id,winner.winner_number_order)" class="cursor-pointer"><u>ID Cartela: #{{winner.selled_card_id}}</u></h4>
          </div>
        </div>
      </b-card>
      <b-card class="col-12 col-lg-6" v-bind:title="'Dados da rodada - ' + round.id">        
        <div class="row">
          <div class="col-12- col-lg-6">
            <b-list-group>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="UserCheckIcon"
                    size="16"
                  />
                </span> 
                <span>Jogadores: </span><strong>{{round.total_users_in}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="GridIcon"
                    size="16"
                  />
                </span>
                <span>Cartelas em jogo: </span><strong>{{round.total_cards_in}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                  />
                </span>
                <span>Valor da cartela: </span><strong>{{round.card_price | toCurrency}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="CircleIcon"
                    size="16"
                  />
                </span>
                <span>Golden Ball:</span>
                <strong class="flex-align-end">{{round.golden_ball}}</strong>
              </b-list-group-item>
            </b-list-group>

          </div>
          <div class="col-12- col-lg-6">
            <b-list-group>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                  />
                </span>
                <span>LINHA: </span><strong>{{(round.final_total_prize * round.first_prize_percentage)/100 | toCurrency}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                  />
                </span>
                <span>LINHA DUPLA: </span><strong>{{(round.final_total_prize * round.second_prize_percentage)/100 | toCurrency}}</strong>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                  />
                </span>
                <span>BINGO: </span><strong>{{(round.final_total_prize * round.third_prize_percentage)/100 | toCurrency}}</strong>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
        <div class="row">
          <b-card class="col-12 col-lg-6" v-bind:title="'Visualizador de cartela'"> 
              <div v-if="graphicCard" id="g-card">
                <h5>Id: #{{graphicCard.id}}</h5>
                <h5>FullId: #{{graphicCard.full_id}}</h5>
                <div class="card-exposed">
                  <span v-for="num in graphicCard.numbers" v-bind:class="[sequenceCheck.indexOf(num.number) >= 0 ? 'marked' : '']" class="col-xs-5ths checked-card-number">{{num.number}}</span>
                </div>
              </div>
              <div v-else>
                <h5 class="text-center">Nenhuma cartela selecionada</h5>
              </div>
          </b-card>
        </div>
      </b-card>
   
      <b-card id="number-box" class="col-12 col-lg-6" title="Numeros sorteados">
        <ul id="picked-numbers">
          <li class="picked-number" v-for="n in round.picked_balls" v-bind:class="[n.prized ? 'prized' : '']">{{n.number}}</li>
        </ul>
      </b-card>
      <b-card id="participants-box" v-if="participantsArray" class="col-12" title="Compradores da rodada">
        <b-card-actions v-for="(cards,participant) in participantsArray" :title="participant + ' - ' + Object.keys(cards).length" action-collapse :collapsed="true">
          <div v-for="card in cards" id="g-card">
            <div class="card-exposed" style="width:20%; float:left; margin:10px;">
            <h5>Id: #{{card.id}}</h5>
            <span v-for="num in card.numbers" v-bind:class="[sequenceCheck.indexOf(num) >= 0 ? 'marked' : '']" class="col-xs-5ths checked-card-number">{{num}}</span>
            </div>
          </div>
        </b-card-actions>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard,BRow,BForm, BCol, BFormGroup, BFormInput, BCardText, BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
export default {
  components: {
    BCard,
    BForm,
    BCardText,
    BCardActions,
    BButton,
    BListGroup, 
    BListGroupItem,
    BRow, BCol, BFormGroup, BFormInput,
  },
  data(){
    return{
      ballNumber : "",
      round:false,
      jackpot: 0,
      closestCards: false,
      graphicCard:false,
      sequenceCheck:false,
      participantsArray:false
    }
  },
  created(){
    this.getCurrentRound();
  },
  methods:{
    getCurrentRound(){
      this.$http.get('/room/round-summary/'+this.$route.params.roundId)
     .then(res => {
       this.round = res.data;
       console.log(this.round)
       this.participants();
      })
    },
    checkCard(id, seq){
      try{
        this.sequenceCheck = false;
        this.sequenceCheck = this.round.picked_balls.slice(0,seq);
        this.sequenceCheck = this.sequenceCheck.map(x => x.number)
        console.log(this.sequenceCheck);
        this.graphicCard = false;
        this.$http.get('/cards/check-card/'+id).then(res => {
          console.log(res.data)
          this.graphicCard = res.data;
        })
      }catch(e){
        console.log(e)
      }
    },
    participants(){
      this.$http.get('room/get-round-users/'+this.$route.params.roundId)
      .then(res => {
        this.participantsArray = res.data;
        this.sequenceCheck = this.round.picked_balls.map(x => x.number)
      })
    }       
  }
}
</script>

<style>
.list-group-item{
  background-color:transparent;
  border:1px solid rgba(255,255,255,0.3);
  justify-content: space-between;
}
#number-box{
  min-height: 640px;
}
#picked-numbers{
    list-style: none;
}
.picked-number{
    font-size: 18px;
    font-weight: bold;
    padding: 10px 10px 10px 12px;
    margin: 5px;
    border-radius: 50px;
    border: 1px solid;
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 26px;
    text-align: center;
    display: inline-block;
}
.picked-number:last-child{
  color:yellow;
}

.picked-number.prized{
  background-color:green;
  color:#fff;
}

#ball-to-call{
  height: 60px;
    font-size: 45px;
    line-height: 60px;
    color: #fff;
    text-align: center;
    border: 1px solid;
    font-weight: bold;
}
.ball-confirm{
  font-size: 70px;
  font-weight: bold;
  text-align: center;
}
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}
.checked-card-number{
  padding: 10px;
  padding: 10px;
  text-align: center;
  border: 1px solid;
}
.checked-card-number.marked{
  background:#ffb72c;
  color:black;
  font-weight: bold;
}
.card-exposed{
  padding: 10px;
  background:#161d31;
  overflow: hidden;
}
</style>